<template lang="pug">
.post-collection__container
  .post-collection__tiles.tiles
    //- статьи в сайдбаре
    template(v-if='inSidebar')
      component.observer.article-card-square(
        v-for='article of tiles.showItems',
        :key='article.id',
        :is="isTrackTheAppearance(article) ? resolveComponent('IntersectionObserver') : 'div'"
        @appear.once='onAppear(article)'
      )
        ArticleCardSquare(
          :title='article.title',
          :imgAlt='article.img_alt || article.title',
          :link='article.url',
          :linkTarget='article.teaserUrl ? "_blank" : ""',
          :images='(article.image && article.image.sizes) || []',
          :defaultImages='article.defaultImg',
          :signText='article.advertLabel',
          :postId='article.id',
          :isPromo='article.isPromo',
          @click-on-article-card='sendAnalytics("сайдбар", $event)'
        )

    //- статьи не в сайдбаре
    template(v-else)
      .before-after-comments-wrapp
        .before-after-comments-wrapp__title(v-if='title') {{ title }}
        .before-after-comments-wrapp__article-card-container
          component.before-after-comments-wrapp__article-card(
            v-for='(article,index) of tiles.showItems',
            :key='article.id',
            :is="isTrackTheAppearance(article) ? resolveComponent('IntersectionObserver') : 'div'"
            @appear.once='onAppear(article)'
          )
            ArticleCardSquare(
              :title='article.title',
              :imgAlt='article.img_alt || article.title',
              :link='getLink(article)',
              :linkTarget='article.teaserUrl ? "_blank" : undefined',
              :images='(article.image && article.image.sizes) || []',
              :defaultImages='article.defaultImg',
              :signText='article.advertLabel',
              :postId='article.id',
              :isPromo='article.isPromo',
              @click-on-article-card='sendAnalytics("комментарии", $event, index + 1)'
            )
</template>

<script lang="ts">
  import { useId, computed, ref, type PropType } from 'vue';
  import IntersectionObserver from '@/components/lh-ui/IntersectionObserver/IntersectionObserver.vue';

  import { shuffleArray } from '~/utils/arrays';
  import ArticleCardSquare from '~/components/ArticleCardSquare.vue';
  import { getRelativeLink, isHomeLink, getFullUrl } from '~/utils';
  import { isHomeHealth, isHomePage } from '~/utils/router';

  export default defineNuxtComponent({
    name: 'PostCollectionContainer',
    components: {
      IntersectionObserver,
      ArticleCardSquare,
    },
    props: {
      articles: {
        type: Array as PropType<IArticle[]>,
        required: true,
      },
      title: {
        type: String as PropType<string>,
        default: '',
      },
      inSidebar: {
        type: Boolean as PropType<boolean>,
        default: false,
      },
      rotationTime: {
        type: Number as PropType<number>,
        default: 30,
      },
    },
    data: () => ({
      tiles: {
        showItems: [] as Array<IArticle>, // показываются в данный момент
        default: {
          count: 4, // количество показываемых тайлов (статей)
        },
        sidebar: {
          count: 1,
        },
      },
      intervalId: null,
      articlesWithLoadedPixels: [] as number[],
    }),

    setup(props) {
      // Keeps state between server and client
      const shuffleArticles = useState(`shuffledArticles_${useId()}`, () =>
        shuffleArray(props.articles),
      );

      const tiles = ref({
        showItems: [] as Array<IArticle>, // показываются в данный момент
        default: {
          count: 4, // количество показываемых тайлов (статей)
        },
        sidebar: {
          count: 1,
        },
      });

      const tilesType = computed(() => {
        return props.inSidebar ? tiles.value.sidebar : tiles.value.default;
      });

      const tilesCount = computed(() => {
        return tilesType.value.count;
      });

      const tilesRotation = () => {
        const showItems = tiles.value.showItems;

        const lastShowArticleIndex = shuffleArticles.value.findIndex((article) => {
          const lastShowArticle = showItems[showItems.length - 1];
          return article.id === lastShowArticle.id;
        });

        const newItems = shuffleArticles.value.slice(
          lastShowArticleIndex + 1,
          lastShowArticleIndex + tilesCount.value + 1,
        );

        if (newItems.length < tilesCount.value) {
          newItems.push(...shuffleArticles.value.slice(0, tilesCount.value - newItems.length));
        }

        tiles.value.showItems = newItems;
      };

      const tilesRotationInit = () => {
        if (shuffleArticles.value.length > tilesCount.value) {
          tiles.value.showItems = shuffleArticles.value.slice(0, tilesCount.value);

          tilesRotation();
        } else {
          tiles.value.showItems = shuffleArticles.value;
        }
      };

      tilesRotationInit();

      return {
        shuffleArticles,
        tilesRotationInit,
        tiles,
        tilesType,
        tilesCount,
        tilesRotation,
      };
    },

    computed: {
      location(): string {
        const routeName = useRoute().name;
        switch (true) {
          case isHomePage(routeName):
            return 'Главная';
          case this.inSidebar:
            return 'Сайдбар';
          default:
            return '';
        }
      },
    },

    destroyed() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
      }
    },

    methods: {
      getLink(article: IArticle) {
        const { url: articleUrl, teaserUrl } = article;

        const internalUrl = teaserUrl ? false : isHomeLink(articleUrl);
        const url = teaserUrl || (internalUrl ? getRelativeLink(articleUrl) : articleUrl);

        return url;
      },
      onAppear(article: IArticle): void {
        const testPixel = article?.testPixel;
        const isNotLoadedPixel = !this.articlesWithLoadedPixels.includes(article.id);
        if (testPixel?.length && isNotLoadedPixel) {
          testPixel.forEach((url) =>
            $fetch(url, { credentials: 'include' }).catch((error) => {
              this.$logger(
                'Error in sending text pixels for ' + article + ' in PostCollectionContainer.vue',
                error,
              );
            }),
          );
          this.articlesWithLoadedPixels.push(article.id);
        }
      },
      isTrackTheAppearance(article: IArticle): boolean {
        return Boolean(article?.testPixel?.length);
      },
      sendAnalytics(category: string, link: string, order: number) {
        const routeName = useRoute().name;
        const isMain = isHomePage(routeName)
          ? 'Главная'
          : isHomeHealth(routeName)
            ? 'Главная здоровье'
            : '';

        !this.inSidebar &&
          !isMain &&
          this.$sendAnalyticsSnowPlow({
            event_name: 'Клик_Коллекция постов',
            par3: getFullUrl(link),
            par4: String(order),
          });

        this.$sendYandexMetrika({
          level1: 'Выбор материала_коллекция постов',
          level4: getFullUrl(link),
          level5: order,
          level6: this.title,
          level8: isMain || category,
        });
      },
    },
  });
</script>

<style lang="scss" scoped>
  .post-collection {
    &__container {
      // костыль, тк в WidgetsRendererContainer
      // не добавляет класс add-margin-bottom
      // из-за нулевой высоты
      min-height: 1px;
    }

    &__tiles {
      display: flex;
      flex-wrap: wrap;
      width: 100%;

      @include tablet {
        display: block;
      }
    }
  }

  .before-after-comments-wrapp {
    border: 1px solid #e7e7e7;
    background-color: #fff;
    padding: 16px;

    @include tablet {
      padding: 24px;
    }

    @include widget-between-posts {
      border-left: none;
      border-right: none;
    }

    &__title {
      margin-bottom: 16px;
      @include fontH2;
    }

    &__article-card {
      min-height: 240px;
      height: 240px;
      max-width: calc(50% - 4px);
      width: calc(50% - 4px);
      &:nth-child(2),
      &:nth-child(4) {
        margin-left: 8px;
      }
      &:nth-child(3),
      &:nth-child(4) {
        margin-top: 8px;
      }

      @include tablet {
        max-width: 25%;
        width: 25%;
        &:nth-child(3),
        &:nth-child(4) {
          margin-top: 0;
        }
        &:not(:first-child) {
          margin-left: 12px;
        }
      }

      :deep(.article-card-square) {
        padding: 8px;
        width: auto;
        height: inherit;
      }
      :deep(.title) {
        font-family: $mainFont;
        font-size: 12px;
        line-height: 17px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        word-break: break-word;
        text-overflow: ellipsis;
        -webkit-line-clamp: 7;
      }
      :deep(.strip-link) {
        margin-top: 4px;
        font-size: 10px;
        line-height: 16px;
      }

      &-container {
        display: flex;
        flex-wrap: wrap;
        @include tablet {
          flex-wrap: nowrap;
        }
      }
    }
  }
</style>
