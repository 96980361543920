<template lang="pug">
.article-card-square
  .cover
    AppPicture(
      :alt='imgAlt || title',
      :images='images',
      :defaultImages='defaultImages',
      :sizesSliced='[390, 640, 748]',
      :sizesByDevice='{ mobile: "100vw", tablet: "748px" }'
    )
  p.title(v-html='title')
  a.strip-link(href='/reklama/', target='_blank', aria-label='Открыть страницу с информацией о рекламе') {{ partnerText }}
  .admin-panel
    AdminControlsPanel(
      v-if='showAdminControlsPanel',
      :viewsCount='viewsCount',
      :editLinkHref='getEditLinkHref(postId)',
      :loading='getLoadingViews(postId)',
      @click-on-restore-button='updateViewsCount(postId)'
    )
  TheLink.article-card-square__link(
    :link='relativeLink',
    :target='linkTarget',
    @click.native='$emit("click-on-article-card", link)'
  )
</template>

<script lang="ts">
  import type { PropType } from 'vue';
  import AppPicture from '@/components/lh-ui/AppPicture/AppPicture.vue';
  import AdminControlsPanel from '@/components/lh-ui/AdminControlsPanel/AdminControlsPanel.vue';
  import TheLink from '~/components/TheLink.vue';
  // @ts-ignore
  import { getEditLinkHref } from '~/utils';
  import { ADS_LABELS } from '~/constants/ads-labels';
  import { useIndexStore } from '~/store';
  import { useArticlesStore } from '~/store/articles';
  import { useUpdatingViewsCount } from '../composables/useUpdatingViewsCount';
  import { removeLifehackerFromUrl } from '~/utils/removeLifehackerFromUrl';

  export default defineNuxtComponent({
    name: 'ArticleCardSquare',
    components: {
      TheLink,
      AdminControlsPanel,
      AppPicture,
    },
    setup() {
      const { loadingViewsIds, updateViewsCount, getLoadingViews } = useUpdatingViewsCount();

      return {
        loadingViewsIds,
        updateViewsCount,
        getLoadingViews,
      };
    },
    props: {
      images: {
        type: Array as PropType<Array<IArticleImg>>,
        required: true,
      },
      defaultImages: {
        type: Array as PropType<Array<IArticleImg>>,
        default: () => [],
      },
      imgAlt: {
        type: String as PropType<string>,
        required: true,
      },
      link: {
        type: String as PropType<string>,
        required: true,
      },
      linkTarget: {
        type: String as PropType<string>,
        default: undefined,
      },
      title: {
        type: String as PropType<string>,
        required: true,
      },
      signText: {
        default: '',
        type: String as PropType<'empty' | 'partner' | 'ads' | 'press-release' | 'social-ads'>,
      },
      postId: {
        required: true,
        type: Number as PropType<number>,
      },
      isPromo: {
        required: true,
        type: Boolean as PropType<boolean>,
      },
    },
    computed: {
      viewsCount() {
        return useArticlesStore().getViewsCount(this.postId);
      },

      showAdminControlsPanel(): boolean {
        return useIndexStore().isAuthenticatedInWordpress;
      },

      partnerText(): string {
        return ADS_LABELS[this.signText] || '';
      },

      relativeLink() {
        return removeLifehackerFromUrl(this.link);
      },
    },
    methods: {
      getEditLinkHref,
    },
  });
</script>

<style lang="scss" scoped>
  $textColor: white;
  $borderColor: #e7e7e7;
  $cardSize: 300px;
  $transitionDuration: 0.3s;

  .article-card-square {
    position: relative;
    z-index: 0;
    width: $cardSize;
    height: $cardSize;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    box-sizing: border-box;
    text-decoration: none;
    border-radius: 4px;
    overflow: hidden;

    &__link {
      z-index: 3;
      position: absolute;
      width: 100%;
      height: 100%;
      content: '';
      top: 0;
      left: 0;
    }

    &::before {
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background-image: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.07) 25.52%,
        rgba(0, 0, 0, 0.52) 62.5%,
        rgba(0, 0, 0, 0.9) 94.35%
      );
    }

    @include hover {
      &::before {
        content: '';
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        -webkit-box-shadow: inset 0px 0px 0px 2px var(--main-color);
        -moz-box-shadow: inset 0px 0px 0px 2px var(--main-color);
        box-shadow: inset 0px 0px 0px 2px var(--main-color);
        border-radius: 4px;
      }

      .cover {
        transform: scale(1.1);
      }

      .title {
        text-decoration-color: $textColor;
        text-decoration-line: underline;
        text-decoration-thickness: 1px;
        // fix LH-1300
        z-index: 3;
      }
    }
  }
  .cover {
    position: absolute;
    left: 0px;
    top: 0px;
    border-radius: 4px;
    z-index: -1;
    width: 100%;
    height: 100%;
    // width: $cardSize;
    // height: $cardSize;
    object-fit: cover;
    transition: all $transitionDuration ease-in-out;
    font-size: 0;
    // небольшая оптимизация
    // https://youtrack.lifehacker.ru/issue/LH-955
    will-change: transform;
    transform: translate3d(0, 0, 0);
  }
  .title {
    z-index: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-word;
    text-overflow: ellipsis;
    -webkit-line-clamp: 5;
    white-space: normal;
    text-decoration: underline;
    text-decoration-thickness: 2px;
    text-decoration-color: transparent;
    transition: text-decoration-color $transitionDuration ease-in-out;
    @include fontH3;

    & {
      color: $textColor;
    }
  }
  .strip-link {
    z-index: 4;
    opacity: 0.8;
    margin-top: 8px;
    text-decoration: none;
    font-family: $mainFont;
    color: #ffffff;
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
  }
  .admin-panel {
    display: flex;
    position: absolute;
    z-index: 4;
    left: 4px;
    top: 4px;
  }
</style>
